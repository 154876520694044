import React from "react";
import { useForm, ValidationError } from "@formspree/react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

function IndividualForm() {
	const [state, handleSubmit] = useForm("moqrkdnw");
	if (state.succeeded) {
		return (
			<div>
				{" "}
				<Form className="w-100" onSubmit={handleSubmit}>
					<Form.Group className="mb-3" controlId="name">
						<Form.Label className="fs-5">Full Name</Form.Label>
						<Form.Control
							required
							className="py-4 fs-5 text-secondary"
							name="name"
							type="text"
						/>
					</Form.Group>
					<ValidationError prefix="Name" field="name" errors={state.errors} />
					<Form.Group className="mb-3" controlId="email">
						<Form.Label className="fs-5">Email Address</Form.Label>
						<Form.Control
							className="py-4 fs-5 text-secondary"
							name="_replyto"
							type="email"
						/>
					</Form.Group>
					<ValidationError prefix="Email" field="email" errors={state.errors} />
					<Form.Group className="mb-3" controlId="address">
						<Form.Label className="fs-5">Address</Form.Label>
						<Form.Control
							required
							className="py-4 fs-5 text-secondary"
							name="address"
							type="text"
						/>
					</Form.Group>
					<ValidationError
						prefix="address"
						field="address"
						errors={state.errors}
					/>
					<Form.Group className="mb-3" controlId="telephone">
						<Form.Label className="fs-5">Telephone Number</Form.Label>
						<Form.Control
							className="py-4 fs-5 text-secondary"
							type="tel"
							name="telephone"
						/>
					</Form.Group>

					<ValidationError
						prefix="Telephone"
						field="telephone"
						errors={state.errors}
					/>
					<Form.Group className="mb-3" controlId="membershipType">
						<Form.Label className="fs-5">
							Please state whether you would like lifetime membership for £30,
							or annual membership for £10 per annum
						</Form.Label>
						<Form.Select
							required
							className="py-4 fs-5 text-secondary"
							name="membershipType"
							defaultValue="" // Ensures that the user has to make a selection
						>
							<option value="" disabled>
								Select membership type
							</option>
							<option value="lifetime">Lifetime Membership - £30</option>
							<option value="annual">Annual Membership - £10 per annum</option>
						</Form.Select>
					</Form.Group>
					<ValidationError
						prefix="membershipType"
						field="membershipType"
						errors={state.errors}
					/>

					{/* Modified ukTaxPayer field with dropdown */}
					<Form.Group className="mb-3" controlId="ukTaxPayer">
						<Form.Label className="fs-5">
							Are you a UK tax payer, and therefore eligible for Gift Aid?
						</Form.Label>
						<Form.Select
							required
							className="py-4 fs-5 text-secondary"
							name="ukTaxPayer"
							defaultValue="" // Ensures that the user has to make a selection
						>
							<option value="" disabled>
								Please select
							</option>
							<option value="yes">Yes</option>
							<option value="no">No</option>
						</Form.Select>
					</Form.Group>
					<ValidationError
						prefix="ukTaxPayer"
						field="ukTaxPayer"
						errors={state.errors}
					/>

					{/* <div
				class="g-recaptcha"
				data-sitekey="6LfMxmofAAAAAJrkK5OeFgJJ53LXPVQJPoNmyxYF"
			></div> */}
					<Button
						variant="primary"
						className=" py-3 mt-4 w-100 w-md-auto text-white px-4 "
						type="submit"
						id="contact-send-btn"
						disabled={state.submitting}
					>
						Submit
					</Button>
				</Form>
				<div className="d-none">{(window.location = "/thank-you")}</div>
			</div>
		);
	}
	return (
		<Form className="w-100" onSubmit={handleSubmit}>
			<Form.Group className="mb-3" controlId="name">
				<Form.Label className="fs-5">Full Name</Form.Label>
				<Form.Control
					required
					className="py-4 fs-5 text-secondary"
					name="name"
					type="text"
				/>
			</Form.Group>
			<ValidationError prefix="Name" field="name" errors={state.errors} />
			<Form.Group className="mb-3" controlId="email">
				<Form.Label className="fs-5">Email Address</Form.Label>
				<Form.Control
					required
					className="py-4 fs-5 text-secondary"
					name="_replyto"
					type="email"
				/>
			</Form.Group>
			<ValidationError prefix="Email" field="email" errors={state.errors} />
			<Form.Group className="mb-3" controlId="address">
				<Form.Label className="fs-5">Address</Form.Label>
				<Form.Control
					required
					className="py-4 fs-5 text-secondary"
					name="address"
					type="text"
				/>
			</Form.Group>
			<ValidationError prefix="address" field="address" errors={state.errors} />
			<Form.Group className="mb-3" controlId="telephone">
				<Form.Label className="fs-5">Telephone Number</Form.Label>
				<Form.Control
					className="py-4 fs-5 text-secondary"
					type="tel"
					name="telephone"
				/>
			</Form.Group>

			<ValidationError
				prefix="Telephone"
				field="telephone"
				errors={state.errors}
			/>
			<Form.Group className="mb-3" controlId="membershipType">
				<Form.Label className="fs-5">
					Please state whether you would like lifetime membership for £30, or
					annual membership for £10 per annum
				</Form.Label>
				<Form.Select
					required
					className="py-4 fs-5 text-secondary"
					name="membershipType"
					defaultValue="" // Ensures that the user has to make a selection
				>
					<option value="" disabled>
						Select membership type
					</option>
					<option value="lifetime">Lifetime Membership - £30</option>
					<option value="annual">Annual Membership - £10 per annum</option>
				</Form.Select>
			</Form.Group>
			<ValidationError
				prefix="membershipType"
				field="membershipType"
				errors={state.errors}
			/>

			{/* Modified ukTaxPayer field with dropdown */}
			<Form.Group className="mb-3" controlId="ukTaxPayer">
				<Form.Label className="fs-5">
					Are you a UK tax payer, and therefore eligible for Gift Aid?
				</Form.Label>
				<Form.Select
					required
					className="py-4 fs-5 text-secondary"
					name="ukTaxPayer"
					defaultValue="" // Ensures that the user has to make a selection
				>
					<option value="" disabled>
						Please select
					</option>
					<option value="yes">Yes</option>
					<option value="no">No</option>
				</Form.Select>
			</Form.Group>
			<ValidationError
				prefix="ukTaxPayer"
				field="ukTaxPayer"
				errors={state.errors}
			/>

			{/* <div
				class="g-recaptcha"
				data-sitekey="6LfMxmofAAAAAJrkK5OeFgJJ53LXPVQJPoNmyxYF"
			></div> */}
			<Button
				variant="primary"
				className=" py-3 mt-4 w-100 w-md-auto text-white px-4 "
				type="submit"
				id="contact-send-btn"
				disabled={state.submitting}
			>
				Submit
			</Button>
		</Form>
	);
}
export default IndividualForm;
