import { useStaticQuery, graphql } from "gatsby";
import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../../components/layout";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import parse from "html-react-parser";
import SecondaryHero from "../../components/secondary-hero";
import EventSection from "../../components/event-section";
import IndividualForm from "../../components/individual-membership-form";

const BecomingAVolunteerPage = ({ transitionStatus }) => {
	const data = useStaticQuery(graphql`
		query {
			heroImgMain: wpMediaItem(title: { eq: "Home Hero Main" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 50
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			wpPageContent(id: { eq: "cG9zdDoxOTI1" }) {
				content
			}
			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);

	const siteUrl = data.site.siteMetadata.siteUrl;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "Membership",
				item: {
					url: `${siteUrl}/membership`,
					id: `${siteUrl}/membership`,
				},
			},
			{
				"@type": "ListItem",
				position: "3",
				name: "Individual Membership",
				item: {
					url: `${siteUrl}/membership/individual-membership`,
					id: `${siteUrl}/membership/individual-membership`,
				},
			},
		],
	};

	return (
		<>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>

			<GatsbySeo
				title="Individual Membership | Friends of Eastbourne Hospital"
				description="Individual Membership"
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/membership/individual-membership`,
					title: "Individual Membership | Friends of Eastbourne Hospital",
					description: "Individual Membership",
					images: [
						{
							url: `${data.heroImgMain?.localFile.publicURL}`,
							width: `${data.heroImgMain?.localFile.childImageSharp.original.width}`,
							height: `${data.heroImgMain?.localFile.childImageSharp.original.height}`,
							alt: `${data.heroImgMain?.altText}`,
						},
					],
				}}
			/>

			<Layout>
				<section className="pb-5">
					<SecondaryHero
						title="Individual Membership"
						description={
							<span>
								Joining the Friends as either an Annual or a Lifetime Member
								<br className="d-none d-md-block" /> is a perfect way to show
								support for the hospital, or simply to say thank you.
							</span>
						}
					/>
				</section>
				<section className=" pb-5">
					<Container>
						<Row>
							<Col className="page-content becoming-a-volunteer">
								{parse(data.wpPageContent.content)}
							</Col>
						</Row>
					</Container>
				</section>
				<section className="pb-md-7 pb-5">
					<Container>
						<Row>
							<Col>
								<IndividualForm />
							</Col>
						</Row>
					</Container>
				</section>
				<section>
					<EventSection />
				</section>
			</Layout>
		</>
	);
};

export default BecomingAVolunteerPage;
